<template lang="pug">
TableV2(
  :isViewAdd="false"
  :headers="headers"
  :items="items"
  :pageCount="0"
  :currentPage="0"
  :querySearch="getListOfflinePriceOfDocuments"
  :isLoading="isLoading"
  isHiddenTableSearch
  isHiddenTableCount
  isHiddenPageCount
  componentInfo="BackOfficeDocumentsPriceOfflineInfo"
  showExpand
)
  Add(slot="AddBlock")
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'PriceOfDocumentOffline',
  components: {
    Add: () => import('../Form')
  },
  data () {
    return {
      headers: [
        { value: 'typeDocName', text: this.$t('typeDoc'), sortable: false },
        { value: 'date_start', text: this.$t('dateEffective'), sortable: false },
        { value: 'full_price', text: this.$t('coming'), sortable: false },
        { value: 'to_sqc', text: this.$t('toSQC'), cellClass: 'border-l', class: 'border-l', sortable: false },
        { value: 'to_qd', text: this.$t('toQD'), sortable: false },
        { value: 'to_td', text: this.$t('toTD'), sortable: false },
        { value: 'to_sc', text: this.$t('toSC'), sortable: false },
        { value: 'to_crewing', text: this.$t('toCrewingManager'), sortable: false },
        { value: 'to_medical', text: this.$t('toMedical'), sortable: false },
        { value: 'to_cec', text: this.$t('toCEC'), sortable: false },
        { value: 'to_mrc', text: this.$t('toMRC'), sortable: false },
        { value: 'to_portal', text: this.$t('toPortal'), cellClass: 'border-r', class: 'border-r', sortable: false },
        { value: 'sum_to_distribution', text: this.$t('all'), sortable: false },
        { value: 'profit', text: this.$t('profit'), sortable: false },
        { text: '', value: 'data-table-expand' }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.backoffice.offlinePriceOfDocuments,
      isLoading: state => state.backoffice.isLoading
    })
  },
  methods: {
    ...mapActions(['getListOfflinePriceOfDocuments'])
  }
}
</script>
